.modal{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    @mixin modal-content{
        position: relative;
        width: 100%;
        max-width: 350px;
        background-color: #fff;
        border-radius: 10px;
        
    }
        .modal-header{
            width: 100%;
            max-width: 380px;
            margin-bottom: 40px;
            padding: 0px 15px;
            .leftPage{
                width: 100px;
                max-width: 50px;
                // left: 50%;
            }
            .rightPage{
                float: right;
                margin-top: -40px;
            }
            h2{
                font-size: 40px;
            }
            .buttonClose{
                float: right;
                margin-top: 7px;
                margin-right: 25px;
                // position: fixed;
            }
        }
        .modal-body{
            // width: 100%;
            height: 70%;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            padding-left: 19px;
            padding-right: 19px;
            overflow-y: auto;
            .logo{
                padding: 0;
                margin: 0;
                // background-color: #98C8F9;
                margin-top: 20px;
                margin-bottom: 20px;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                border: none;
                border-radius: 10px;
                text-align: center;
                // img{
                //     width: 50px;
                // }
            }
            .textTitle{
                margin-bottom: 20px;
                text-align: center;
                h2{
                    font-size: 14px;
                    font-weight: bold;
                }
            }
            .content{
                padding: 0px 30px 20px 30px;
                margin-bottom: 10px;

            }
            p{  
                margin-top: 10px;
                font-size: 12px;
            }
            h5{
                font-size: 12px;
            }
            .center{

                text-align: center;
            }
            .bold{
                font-weight: bold;
            }
            .titlePopup{
                h5{
                    text-align: left;
                    font-size: 14px;
                    font-weight: 400;
                    margin-bottom: 10px;
                    font-weight: 700;
                }
            }
            .textPopup{
                h5{
                    text-align: left;
                    font-size: 12px;
                    color: #545455;
                    font-weight: 400;
                    margin-bottom: 25px;
                }
            }
            .boxEmpty{
                margin: auto;
                margin-bottom: 30px;
                width: 100%;
                p{
                    margin-bottom: 20px;
                }
            }
            .containerBox {
                display: flex;
                flex-wrap: wrap;
                font-size: 30px;
            }
            .itemBox {
                padding: 10px;
                flex: 1;
                margin: auto;
                h4{
                    text-align: left;
                    font-size: 20px;
                    color: #0E4A87;
                    font-weight: 700;
                    margin-bottom: 15px;
                }
                h5{
                    text-align: left;
                    font-size: 12px;
                    color: #545455;
                    font-weight: 400;
                    margin-bottom: 5px;
                }
                img{
                    width: 100%;
                    max-width: 150px;
                }
                .logoApps{
                    display: flex;
                    flex-direction: column;
                    margin-top: 10px;
                    row-gap: 10px;
                    img{
                        width: 90px;
                        height: auto;
                    }
                }
                ul.point {
                    margin-left: 30px;
                    list-style-type: disc;
                }
                .titleGroup{
                    margin-bottom: 15px;
                    h4{
                        text-align: left;
                        font-size: 20px;
                        color: #0E4A87;
                        font-weight: 700;
                        margin-bottom: 5px;
                    }
                }
            }
        }
        .modal-footer{
            width: 100%;
            max-width: 380px;
        }
        .modal-body::-webkit-scrollbar {
                width: 8px;
        }
        .modal-body::-webkit-scrollbar-track {
            -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); 
            border-radius: 5px;
        }
        .modal-body::-webkit-scrollbar-thumb {
            -webkit-box-shadow: inset 0 0 8px #545455; 
            border-radius: 5px;
        }
        .modal-body::-webkit-scrollbar-thumb:hover {
            -webkit-box-shadow: inset 0 0 12px #545455; 
            border-radius: 5px;
        }
        .buttonBasic {
            background-color: #FFFFFF;
            color: #388BDD;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 600;
            border: 1px solid #388BDD;
            border-radius: 15px;
            width: 100%;
            max-width: 240px;
            height: 33px;
            margin-left: auto;
            margin-right: auto;
        }
        .btnnoborder{
            background-color: #FFFFFF;
            border: none;
        }
        .btnfilled1{
            color: #FFFFFF;
            background-color: #1A3B6B;
        }
        .fontBlue{
            color: #1A3B6B;
        }
        hr{
            border: 1px solid #C1C1C2;
            width: 90%;
            margin-top: 15px;
            margin-bottom: 15px;
        }

    .modal-content-sm{
        @include modal-content;
        height: auto;
    }
    .modal-content-lg{
        @include modal-content;
        height: 100%;
        max-height: 560px;
        margin-top: 70px;
    }
}

// for mobile device
@media only screen and (max-width: 480px) {
    @mixin modal-content{
        position: relative;
        // padding: 0px 30px;
        // margin-top: 100px;
        width: 70% !important;
        // height: 80%; 
    }
    .modal-content-lg{
        @include modal-content;
        height: 80% !important;
        margin-top: 75px !important;
    }
    .buttonBasic{
        height: 33px !important;
        width: 80% !important;
    }
    .modal-body{
        height: 70% !important;
    }
    hr{
        width: 85% !important;
    }
    .textPopup{
        h5{
            font-size: 12px !important;
        }
    }
    .itemBox{
        h5{
            font-size: 12px !important;
        }
        .logoApps{
            row-gap: 5px !important;
            img{
                width: 70px !important;
                height: auto;
            }
        }
    }
}

// for tablet device
@media only screen and (min-width: 481px) and (max-width: 820px){
    @mixin modal-content{
        position: relative;
        // padding: 0px 30px;
        // margin-top: 100px;
        width: 80% !important;
        // height: 80%; 
    }
    .modal-content-lg{
        @include modal-content;
        height: 100% !important;
        margin-top: 75px !important;
    }
    .buttonBasic{
        height: 33px !important;
        width: 80% !important;
    }
    .modal-body{
        height: 70% !important;
    }
    hr{
        width: 85% !important;
    }
    .textPopup{
        h5{
            font-size: 12px !important;
        }
    }
    .itemBox{
        h5{
            font-size: 12px !important;
        }
        .logoApps{
            row-gap: 5px !important;
            img{
                width: 70px !important;
                height: auto;
            }
        }
    }
}
