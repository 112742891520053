.containerVacancy{
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: auto;
    margin-bottom: 100px;
    .searchBox{
        padding: 40px 40px;
        border-radius: 6px;
        border: solid 1px #FFFFFF;
        box-shadow: 0px 10px 31px rgb(0 0 0 / 12%);
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 70px;
        .text{
            margin-bottom: 20px;
            h4{
                font-size: 18px;
                font-weight: 600;
            }
        }
        .innerBox{
            width: 100%;
            // background-color: red;
            display: grid;
            // flex-wrap: wrap;
            grid-template-columns: 5fr 1fr; /* creates 2 columns */
            // grid-template-rows: 4fr;
            column-gap: 50px;
            .inputPage{
                input{
                    padding: 14px 16px;
                    border-radius: 6px;
                    border: solid 1px #388BDD;
                    width: 100%;
                    box-sizing: border-box;
                    color: #A3A4A5;
                }
                img{
                    position: absolute;
                    margin-left: -45px;
                    margin-top: 10px;
                }
            }
            .buttonSearch{
                // width: 20%;
                // padding: 14px 100px 14px 70px;
                button{
                    padding: 14px 100px 14px 70px;
                    // margin-left: 40px;
                    border-radius: 6px;
                    border: solid 1px #388BDD;
                    width: 100%;
                    box-sizing: border-box;
                    background-color: #388BDD;
                    color: #FFFFFF;
                }img{
                    position: absolute;
                    margin-top: /*-30px;*/ 15px;
                    margin-left: /*150px;*/ -90px;
                    height: 20px;
                    width: 20px;
                }
            }
        } 
    }
    .buttonBasic {
        background-color: #FFFFFF;
        color: #388BDD;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 600;
        border: none; //1px solid #388BDD;
        border-radius: 15px;
        width: 100%;
        max-width: 240px;
        height: 33px;
        margin-left: auto;
        margin-right: auto;
        padding: 10px 20px 10px 20px;
    }
    // button{
    //     width: 100%;
    //     padding: 10px 20px 10px 20px;
    //     font-size: 12px;
    //     font-weight: 600;
    //     border: none;
    //     border-radius: 15px;
    // }
    .contentVacancy{
        h2{
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 25px;
            text-align: left;
        }
        .searchInfo{
            margin-bottom: 30px;
            img{
                width: 15px;
                height: 15px;
            }
            .text{
                position: absolute;
                margin-left: 25px;
                margin-top: -17px;
                h5{
                    font-size: 12px;
                }
            }
        }
        .cardBox{
            display: flex;
            margin-left: auto;
            margin-right: auto;
            flex-wrap: wrap;
            gap: 20px;
            margin-bottom: 20px;
            .cardVacancy { 
                background: #FFFFFF;
                width: 250px;
                height: 260px;
                margin: auto;
                padding: auto;
                border-radius: 15px;
                box-shadow: 0px 10px 31px rgb(0 0 0 / 12%);
                transition: all 0.3s;
                &:hover {
                    box-shadow: 0px 10px 20px #0000005c;
                    transform: scale(1.03);
                    transition: all 0.3s;
                }
                img {
                    width: 100%;
                    height: 55%;
                    margin-bottom: 5px;
                    border-top-right-radius: 15px;
                    border-top-left-radius: 15px;
                    object-fit: cover !important;
                }
                .text{
                    margin: 5px 5px 5px 15px;
                    h3{
                        margin-bottom: 7px;
                        font-size: 12px;
                    }
                    h4{
                        font-size: 9px;
                        color: #7E7F80;
                        margin-bottom: 5px;
                    }
                }
                .location{
                    width: 100%;
                    display: flex;
                    margin-bottom: 12px;
                    img{
                        width: 7%;
                    }
                    .text{
                        margin-left: 19px;
                        margin-top: 5px;
                        margin-right: 5px;
                        position: absolute;
                    }
                }
                button{
                    padding: 5px 15px 5px 15px;
                    background-color: #388BDD;
                    color: #FFFFFF;
                    font-size: 11px;
                    border-radius: 10px;
                    border: none;   
                }
                h3{
                    font-size: 18px;
                    font-weight: 600;
                }
               
                p {
                    margin: 0px;
                    font-size: 12px;
                }
            }
        }
    }
    .notFound{
        text-align: center;
        margin-top: 150px;
        margin-bottom: 400px;
        img{
            width: 200px;
            height: 200px;
            margin-bottom: 30px;
        }
        h4{
            font-size: 14px;
            font-weight: 600;
            color: #7E7F80;
        }
    }
    .detail{
        width: 100%;
        max-width: 800px;
        margin: auto;
        padding: auto;
        margin-top: 20px;
        .boxInfo{
            width: 100%;
            max-width: 800px;
            margin: auto;
            text-align: center;
            img {
                // width: 600px;
                height: 400px;
                object-fit: cover !important;
                margin-bottom: 30px;
            }
        }
        h1{
            font-size: 48px;
            color: #0E4A87;
            font-weight: 700;
            margin-bottom: 25px;
        }
        .description{
            width: 100%;
            max-width: 800px;
            margin: auto;
            margin-bottom: 50px;
            // padding: 0px 250px 0px 250px;
            .workDetail{
                margin: auto;
                margin-bottom: 50px;
                padding: 20px 20px 20px 20px;
                background-color: #F5FAFF;
                border-radius: 16px;
                .rowContent{
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    column-gap: 180px;
                    row-gap: 40px;
                    margin-bottom: 40px;
                }
                .columnContent{
                    display: flex;
                    flex-direction: column;
                    row-gap: 40px;
                }
                .boxContent{
                    display: flex;
                    column-gap: 10px;
                    img{
                        width: 30px;
                        height: 30px;
                        display: absolute;
                    }
                    .text{
                        .title{
                            font-size: 18px;
                            color: #1B69B8;
                            font-weight: 600;
                            margin-bottom: 6px;
                        }
                        .textContent{
                            font-size: 16px;
                            font-weight: 400;
                        }

                    }
                }
            }
            h2{
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 30px;
                text-align: left;
            }
            h3{
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 20px;
            }
            h4{
                font-size: 16px;
                margin-bottom: 15px;
            }
            p{
                font-size: 12px;
            }
        }
        .boxMain{
            padding: 30px 20px 30px 20px;
            margin: auto;
            margin-bottom: 30px;
            width: 100%;
            max-width: 800px;
            border: 1px solid #C5E1FD;
            box-shadow: 5px 6px 12px -4px rgba(0, 0, 0, 0.08);
            border-radius: 8px;
            h3{
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 20px;
            }
            .boxPage{
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                .boxIcon{
                    padding: 10px 10px 10px 10px;
                    border-radius: 8px;
                    background-color: #F5FAFF;
                    .image{
                        width: 20px;
                        height: 20px;
                    }
                    .text{
                        float: left;
                        margin-top: -15px;
                        margin-left: 35px;
                        h4{
                            font-size: 13px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        .boxEmpty{
            padding: 30px 30px 30px 30px;
            margin: auto;
            margin-bottom: 30px;
            width: 90%;
            max-width: 700px;
            p{
                margin-bottom: 20px;
            }
        }
        .boxPrimary{
            background-image: linear-gradient(180deg, #FFFFFF 9.32%, #EDF6FF 100%);
            padding: 30px 20px 30px 20px;
            margin: auto;
            margin-bottom: 30px;
            width: 100%;
            max-width: 700px;
            border: 1px solid #C5E1FD;
            box-shadow: 5px 6px 12px -4px rgba(0, 0, 0, 0.08);
            border-radius: 8px;
        }
        .boxDanger{
            background: #FFF5F5;
            padding: 30px 20px 30px 20px;
            margin: auto;
            margin-bottom: 30px;
            width: 100%;
            max-width: 700px;
            border: 1px solid #C5E1FD;
            box-shadow: 5px 6px 12px -4px rgba(0, 0, 0, 0.08);
            border-radius: 8px;
        }
        .containerBox {
            display: flex;
            flex-wrap: wrap;
            font-size: 30px;
            text-align: center;
        }
        .itemBox {
            padding: auto;
            margin: auto;
            flex: 1;
            h4{
                text-align: left;
                font-size: 20px;
                color: #0E4A87;
                font-weight: 700;
                margin-bottom: 15px;
            }
            img{
                // width: 100%;
                width: 270px;
                margin: auto;
                padding: auto;
            }
            .btnfilled{
                // padding: 10px 20px 10px 20px;
                // font-size: 12px;
                // font-weight: 600;
                border: none;
                // border-radius: 15px;
                width: 60%;
                float: left;
                margin-top: 0px;
                background-color: #1A3B6B;
                color: #FFFFFF;
            }
            ul.point {
                margin-left: 30px;
                list-style-type: disc;
            }
            .titleGroup{
                margin-bottom: 15px;
                h4{
                    text-align: left;
                    font-size: 20px;
                    color: #0E4A87;
                    font-weight: 700;
                    margin-bottom: 5px;
                }
            }
        }
        .splitLogoApps{
            display: flex;
            flex: 1;
            // width: 20%;
            gap: 30px;
            img{
                width: 100%;
                max-width: 150px;
            }
            margin-bottom: 20px;
        }
        .splitButtonAccount{
            display: flex;
            flex:1;
            width: 100%;
            gap: 30px;
            // button{
            //     width: 100%;
            //     padding: 10px 20px 10px 20px;
            //     font-size: 12px;
            //     font-weight: 600;
            //     border: none;
            //     border-radius: 15px;
            // }
            .btnblank{
                width: 100%;
                max-width: 160px;
                border: 1px solid #388BDD;
                background-color: white;
                color: #388BDD;
            }
            .btnfilled{
                width: 100%;
                max-width: 160px;
                margin-top: 0px;
                background-color: #1A3B6B;
                color: #FFFFFF;
                
            }
        }

        hr{
            color: #E5E5E5;
            width: 840px;
            margin-bottom: 60px;
        }
    }
    h2{
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 70px;
        margin-top: 40px;
    }
    
    .buttonPage{
        width: 70%;
        margin-bottom: 50px;
        margin-top: 60px;
        margin-left: auto;
        margin-right: auto;
        // background-color: #1A3B6B;
        // button{
        //     padding: 10px 20px 10px 20px;
        //     font-size: 12px;
        //     font-weight: 600;
        //     border: none;
        //     border-radius: 15px;
        // }
        .btnblank{
            width: 48%;
            border: 1px solid #388BDD;
            background-color: white;
            color: #388BDD;
        }
        .btnfilled{
            width: 48%;
            float: right;
            margin-top: 0px;
            background-color: #1A3B6B;
            color: #FFFFFF;   
        }
        .btnfilled1{
            width: 100%;
            max-width: 560px;
            margin-top: 0px;
            background-color: #1A3B6B;
            color: #FFFFFF;   
        }
    }
    .formGroup {
        position: relative;
        margin-bottom: 20px;
        label {
            display: block;
            margin-bottom: 8px;
            font-size: 12px;
            font-weight: 500;
            font-weight: 600;
        }
        input {
            padding: 14px 16px;
            border-radius: 6px;
            border: solid 1px #e0e0e0;
            width: 100%;
            box-sizing: border-box;
            font-family: Arial, Helvetica, sans-serif;
            z-index: 10000;
        }
        textarea {
            padding: 14px 16px;
            border-radius: 6px;
            border: solid 1px #e0e0e0;
            width: 100%;
            height: 110px;
            box-sizing: border-box;
            font-family: Arial, Helvetica, sans-serif;
        }
        select {
            padding: 14px 16px;
            border-radius: 6px;
            border: solid 1px #e0e0e0;
            width: 100%;
            box-sizing: border-box;
        }
        .PhoneInputInput{
            z-index: -1;
        }  
    }
    .split{
        display: grid;
        grid-template-columns: 1fr 1fr; /* creates 2 columns */
        gap: 20px;
    }
    .upload-btn-wrapper {
        padding: 10px 16px;
        border-radius: 6px;
        border: solid 1px #e0e0e0;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        display: inline-block;
        .text{
            font-size: 14px;
            font-weight: 600;
            margin-top: 8px;
            float: left;
        }
        .btn {
            border: none;
            color: #e0e0e0;
            background-color: #1A3B6B;
            padding: 8px 25px;
            border-radius: 15px;
            font-size: 12px;
            font-weight: bold;
            float: right;
        }
    }
    .upload-btn-wrapper input[type=file] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
    .pagination{
        margin-top: 50px;
        margin-bottom: 130px;
        .desc{
            position: absolute;
            left: 210;
        }
        .navPage{
            // position: absolute;
            float: right;
            right: 0;
            @mixin navButton{
                padding: 10px 20px;
                border-radius: 8px;
                display: inline;
                margin-right: 20px;
                text-decoration: none;   
            }
            a{
                color: #000000;
            }
            a:hover, .active{
                border: 1px #3479BF solid;
                background-color: #3479BF;
                color: #FFFFFF;
            }
            .navPrevNext{
                @include navButton;
                border: 1px #3479BF solid;
            }
            .navNumber{
                @include navButton;
                border: 1px #3479BF solid;
                // background-color: #3479BF;
            }  
        }
    }
}

// for mobile device
@media only screen and (max-width: 480px){
    .containerVacancy{
        width: 90%;
    }
    .boxMain{
        width: 87% !important;
        padding: 10px 20px 10px 20px !important;
    }
    .boxPrimary{
        width: 87% !important;
        padding: 10px 20px 10px 20px !important;
    }
    .boxDanger{
        width: 87% !important;
        // padding-left: 0 auto !important;
        padding: 10px 20px 10px 20px !important;
        // margin: auto !important;
    }
    .boxEmpty{
        width: 80% !important;
        padding: auto !important;
        margin: auto !important;
    }
    .boxInfo{
        img{
            width: 90%;
        }
    }
    .description{
        width: 90% !important;
        .workDetail{
            margin: auto !important;
        }
        .boxContent .textContent{
            width: 220px;
            overflow: hidden;
            text-overflow: ellipsis; 
            margin-right: 20px !important;
        }
    }
    .itemBox{
        width: 90%;
        margin: auto;
        // padding: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        img{
            margin: auto !important;
            padding: auto !important;
        }
        .btnfilled{
            width: 70% !important;
        }
    }
    .splitButtonAccount{
        display: flex;
        flex: 1;
        width: 100% !important;
        gap: 30px !important;
        .buttonBasic {
            width: 100%;
            max-width: 240px !important;
            height: 33px !important;
            // padding: 23px 20px 23px 20px !important;
            padding: 10px 20px 10px 20px !important;
            font-size: 12px !important;
        }
        .btnblank{
            width: 100% !important;
            max-width: 150px !important;
        }
        .btnfilled{
            width: 100% !important;
            max-width: 150px !important;
        }
    }
    hr{
        color: #E5E5E5;
        width: 300px !important;
        margin-bottom: 60px;
    }
    .innerBox{
        // grid-template-columns: 1fr; /* creates 2 columns */
        display: flex !important;
        flex-wrap: wrap !important;
        row-gap: 10px !important;
        justify-content: center !important;
        .inputPage{
            width: 100% !important;
        }
        .buttonSearch{
            width: 100%;
            img{
                float: right !important;
                // position: absolute;
                margin-top: /*-30px;*/ 14px !important;
                margin-left: /*150px;*/ -121px !important;
            }
        }
    }
    .boxIcon{
        width: 100px;
    }
    .buttonPage{
        .btnfilled1{
            width: 100% !important;
        }
    }
}

// for tablet device
@media only screen and (min-width: 481px) and (max-width: 850px){
    .containerVacancy{
        width: 80%;
    }
    .boxMain{
        width: 90% !important;
        padding: 10px 20px 10px 20px !important;
    }
    .boxPrimary{
        width: 90% !important;
        padding: 10px 20px 10px 20px !important;
    }
    .boxDanger{
        width: 90% !important;
        // padding-left: 0 auto !important;
        padding: 10px 20px 10px 20px !important;
        // margin: auto !important;
    }
    .boxEmpty{
        width: 80% !important;
        padding: auto !important;
        margin: auto !important;
        .containerBox{
            margin: auto !important;
            padding: auto !important;
            .itemBox{
                align-items: center !important;
                justify-content: center !important;
            }
        }
    }
    .boxInfo{
        img{
            width: 90%;
        }
    }
    .description{
        width: 95% !important;
        .workDetail{
            margin: auto !important;
            .rowContent{
                column-gap: 80px !important;
            }
        }
    }
    .itemBox{
        width: 90%;
        margin: auto;
        // padding: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        // margin-left: 100px !important;
        .btnfilled{
            width: 80% !important;
        }
        img{
            margin: auto !important;
            padding: auto !important;
        }
    }
    .splitButtonAccount{
        display: flex;
        flex: 1;
        width: 100% !important;
        gap: 20px !important;
        .buttonBasic {
            width: 100%;
            max-width: 240px !important;
            height: 33px !important;
            // padding: 23px 20px 23px 20px !important;
            padding: 10px 20px 10px 20px !important;
            font-size: 12px !important;
        }
        .btnblank{
            width: 100% !important;
            max-width: 150px !important;
        }
        .btnfilled{
            width: 100% !important;
            max-width: 150px !important;
        }
    }
    hr{
        color: #E5E5E5;
        width: 600px !important;
        margin-bottom: 60px;
    }
    .boxIcon{
        width: 100px;
    }
    .boxEmpty .containerBox .itemBox{
        width: 50% !important;
    }
    .buttonPage{
        .btnfilled1{
            width: 100% !important;
        }
    }
}